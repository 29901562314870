import React from "react"
import Obfuscate from "react-obfuscate"
import { FaPhone, FaEnvelope } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactFr = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Contactez-moi"
      description="Contacter Ludovic Guyot - Lacuzon Loisirs"
    />
    <div className="has-text-centered mt-6">
      <div style={{ color: "#000", fontSize: 30 }}>
        <FaPhone /> 06 88 59 92 29
      </div>
      <div style={{ color: "#000", fontSize: 30 }}>
        <FaEnvelope />{" "}
        <Obfuscate
          style={{ color: "#000" }}
          email="contact@lacuzonloisirs.fr"
        />
      </div>
    </div>
    <form action="/post.php" method="post" style={{width: 600, margin: '40px auto'}}>
      <div class="field">
        <label class="label" htmlFor="name">Votre nom</label>
        <div class="control">
          <input class="input" type="text" placeholder="Nom" name="name" id="name" required maxLength="254" />
        </div>
      </div>
      <div class="field">
        <label class="label" htmlFor="email">Votre adresse de courriel</label>
        <div class="control">
          <input class="input" type="email" placeholder="Email" name="email" id="email" required maxLength="254" />
        </div>
      </div>
      <div class="field">
        <label class="label" htmlFor="subject">Sujet</label>
        <div class="control">
          <input class="input" type="text" placeholder="Sujet" name="subject" id="subject" required maxLength="254" />
        </div>
      </div>
      <div class="field">
        <label class="label" htmlFor="message">Message</label>
        <div class="control">
          <textarea id="message" name="message" required style={{width: '100%'}} rows="9" maxLength="10000"></textarea>
        </div>
      </div>
      <div class="field">
        <label class="label" htmlFor="robot">Quelles sont les 3 premières lettres du mot Lacuzon ?</label>
        <div class="control">
          <input class="input" type="text" placeholder="3 premières lettres" name="robot" id="robot" required maxLength="254" />
        </div>
      </div>
      <button type="submit" className="button">Envoyer le message</button>
    </form>
  </Layout>
)

export default ContactFr
